import React, { useEffect, useState } from "react";
import { IProgress } from "./types";

const App = () => {
  const [progress, setProgress] = useState<IProgress | null>(null);

  useEffect(() => {
    fetch("/progress.json").then(async (res) => {
      const data = await res.json();
      setProgress(data);
    });
  }, []);

  const calculateScore = (
    person: IProgress["osoby"][number],
    scoring: IProgress["scoring"]
  ) => {
    let totalScore = 0;

    for (const [key, value] of Object.entries(person)) {
      if (value === true && key in scoring) {
        totalScore += scoring[key as keyof IProgress["scoring"]];
      }
    }

    return totalScore;
  };

  const sortedPeople = progress
    ? [...progress.osoby]
        .map((person) => ({
          ...person,
          score: calculateScore(person, progress.scoring),
        }))
        .sort((a, b) => b.score - a.score)
    : [];

  return !progress ? (
    <div>LADOWANIE STATYSTYK</div>
  ) : (
    <main>
      <img className="bg" src="/bg.jpg" alt="bg" />
      <div className="kolejka">
        <h1>Kolejka Po Znaki</h1>
        <div className="znaki">
          <div className="znak">
            <h2>Znak Shi</h2>
            {sortedPeople
              .filter((osoba) => !osoba.znak_shi)
              .map((osoba) => (
                <div key={osoba.nick}>
                  <strong>{osoba.nick}</strong> ({osoba.score}pkt)
                </div>
              ))}
          </div>
          <div className="znak">
            <h2>Znak Li</h2>
            {sortedPeople
              .filter((osoba) => !osoba.znak_hp)
              .map((osoba) => (
                <div key={osoba.nick}>
                  <strong>{osoba.nick}</strong> ({osoba.score}pkt)
                </div>
              ))}
          </div>
          <div className="znak">
            <h2>Znak Yin</h2>
            {sortedPeople
              .filter((osoba) => !osoba.znak_ziemia)
              .map((osoba) => (
                <div key={osoba.nick}>
                  <strong>{osoba.nick}</strong> ({osoba.score}pkt)
                </div>
              ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default App;
